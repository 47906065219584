/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireProductDetailsEvent } from '../../event/product';

const addFireProductDetailEvent = (args, callback, instance) => {
    callback(...args);
    fireProductDetailsEvent(instance.props.product, instance.state.skuForGtm);
};

/* sidea teofilo la funzione updatemeta è stata sostituita da quella creata appositamente */

export default {
    // 'Route/ProductPage/Container': {
    //     'member-function': {
    //         productDetailsDataLayer: addFireProductDetailEvent
    //     }
    // }, disattivato a favore del click sulla variante, se il sistema non preseleziona la prima variante come su quality, attivare questa per il primo render
    'Component/Product/Container': {
        'member-function': {
            productVariantGtmDetails: addFireProductDetailEvent
        }
    }
};
